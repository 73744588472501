import Swal from 'sweetalert2'

export const numberFormat = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const Alert = (msg, icon = 'error') => {
    Swal.fire({
        title: '',
        text: msg,
        allowOutsideClick: false,
        icon: icon,
        confirmButtonColor: "#DD6B55",
    })
}

export const strToBool = (s) => {
    let regex = /^\s*(true|1|on)\s*$/i
    return regex.test(s);
}

export const parseRedaman = (data) => {
    // Use regex to find the relevant data
    const pattern = /gpon-onu_(\d+\/\d+\/\d+:\d+)\s+OLT\s+ONU\s+Attenuation\s+-+\s+up\s+Rx\s*:(-?\d+\.\d+)\(dbm\)\s*Tx:(-?\d+\.\d+)\(dbm\)\s*(\d+\.\d+)\(dB\)\s+down\s+Tx\s*:(-?\d+\.\d+)\(dbm\)\s*Rx:(-?\d+\.\d+)\(dbm\)\s*(\d+\.\d+)/;
    const match = data.match(pattern);

    // Extract data from regex match
    if (match) {
        const gpon_onu = match[1];
        const up_olt_rx = match[2];
        const up_onu_tx = match[3];
        const up_attenuation = match[4];
        const down_olt_tx = match[5];
        const down_onu_rx = match[6];
        const down_attenuation = match[7];

        // Create structured JSON
        const structured_json = {
            "gpon-onu": gpon_onu,
            "up": {
                "OLT_Rx": `${up_olt_rx} dBm`,
                "ONU_Tx": `${up_onu_tx} dBm`,
                "Attenuation": `${up_attenuation} dB`
            },
            "down": {
                "OLT_Tx": `${down_olt_tx} dBm`,
                "ONU_Rx": `${down_onu_rx} dBm`,
                "Attenuation": `${down_attenuation} dB`
            }
        };

        // Output the structured JSON
        console.log(structured_json);
        return structured_json;
    } else {
        return 'No match found in the response data';
    }
}