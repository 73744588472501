import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import jwtDecode from "jwt-decode";
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { Alert } from '../utils/Helper'
import axios from 'axios'


const PrivateRoute = (props) => {

    const dispatch = useDispatch()

    const checkAuth = () => {
        const token = localStorage.getItem('authJwt')
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const decoded = jwtDecode(token);
        const exp = moment.unix(decoded.exp)
        if (moment().isBefore(exp)) {
            dispatch({ type: 'SESSION', user: decoded })
            return true;
        } else {
            Alert('Sesi berakhir, silakan login ulang!')
            dispatch({ type: 'SESSION', user: null })
            localStorage.removeItem('authJwt')
            return false;
        }
    }


    if (!localStorage.getItem('authJwt')) {
        return (<Redirect to="/login" />)
    } else {
        const isAuth = checkAuth()
        if (isAuth) return (<Route {...props} />);
        return (<Redirect to="/login" />)
    }


}
export default PrivateRoute